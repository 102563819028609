<template>
  <!-- 由于使用了getList，这里apiParams需加上不同参数，避免缓存 -->
  <custom-api-select
    :api="getList"
    :api-params="Object.assign({selector:props.selector},apiParams)"
    :immediate="true"
    :value-field="valueField"
    :label-field="labelField"
  />
</template>
<script lang="ts" setup>
import { apiSelectorProps } from './props'
import { selectorApi } from '@/api/selector'
import { isFunction } from 'lodash-es'
const props = defineProps(apiSelectorProps)
const getList = () => (new Promise(async (resolve, reject) => {
  const fetchApi = props.api ?? selectorApi[props.selector]
  try {
    let res:Recordable[] = []
    if (isFunction(fetchApi)) {
      res = await fetchApi({
        page: 1,
        pageSize: 3000,
        ...props.apiParams
      })
    }
    resolve(res)
  } catch {
    resolve([])
  }
}))
</script>
