// ant-design 库组件
import Input, { InputProps } from 'ant-design-vue/es/input'
import InputNumber, { InputNumberProps } from 'ant-design-vue/es/input-number'
import TimePicker, { TimePickerProps } from 'ant-design-vue/es/time-picker'
import Switch, { SwitchProps } from 'ant-design-vue/es/switch'
import Radio, { RadioProps } from 'ant-design-vue/es/radio'
import Select, { SelectProps } from 'ant-design-vue/es/select'
import Cascader, { CascaderProps } from 'ant-design-vue/es/cascader'
import { CheckboxProps } from 'ant-design-vue/es/checkbox'
import Checkbox from './common-components/checkbox.vue'
import CheckboxGroup from './common-components/checkbox-group.vue'

import { DatePickerProps, MonthPickerProps, WeekPickerProps, RangePickerProps } from 'ant-design-vue/es/date-picker'

// 若从 es 模块中引用 datePicker，将导致 datePicker 样式丢失，无法正常显示，故在此通过 ant-design-vue 中导入
import { DatePicker } from 'ant-design-vue'


// 业务无关自定义通用组件
import InputMobile, { InputMobileProps } from './common-components/input-mobile'
import RangeMoneyInput, { RangeMoneyInputProps } from './common-components/range-money-input'
import InputMoney, { InputMoneyProps } from './common-components/input-money'
import RegionSelect, { RegionSelectProps } from './common-components/region-select'
import PureDisplay, { PureDisplayProps } from './common-components/pure-display'
import CustomApiSelect, { CustomApiSelectProps } from './common-components/custom-api-select'
import InputTextArea, { InputTextAreaProps } from './common-components/input-textarea'
import SnInput from './common-components/sn-input.vue'
import WangEditor from './common-components/wang-editor.vue'

// 业务组件
import IdCardInput, { IdCardInputProps } from './business-components/id-card-input'
import Mammoth from './business-components/mammoth.vue'
import Upload, { UploadProps } from './business-components/upload'
import ParseExcel from './business-components/parse-excel/parse-excel.vue'
import ExcelImport, { ExcelImportProps } from './business-components/excel-import'
import ExcelImportOfFile, { ExcelImportOfFileProps } from './business-components/excel-import-of-file'
import InputCaptcha, { InputCaptchaProps } from './business-components/input-captcha'
import ReferenceSelect, { ReferenceSelectProps } from './business-components/reference-select'
import CommonApiSelect, { CommonApiSelectProps } from './business-components/common-api-select'
import MultipleDateChoice, { MultipleDateChoiceProps } from './business-components/multiple-date-choice'
import ApiSelector, { ApiSelectorProps } from './business-components/api-selector'


export const componentTypeMap = {

  /* 基础组件 */
  SnInput,
  WangEditor,

  Input,
  InputPassword: Input.Password,
  InputSearch: Input.Search,
  InputNumber,
  Cascader,
  Select,
  DatePicker,
  MonthPicker: DatePicker.MonthPicker,
  WeekPicker: DatePicker.WeekPicker,
  DateRangePicker: DatePicker.RangePicker,
  TimePicker,
  Switch,
  Radio,
  RadioGroup: Radio.Group,
  Checkbox,
  CheckboxGroup,


  // 自定义通用组件
  InputMobile,
  InputMoney,
  RangeMoneyInput,
  RegionSelect,
  PureDisplay,
  CustomApiSelect,
  InputTextArea,

  // 业务组件，调用特定业务接口
  IdCardInput,
  Mammoth,
  Upload,
  ParseExcel,
  ExcelImport,
  ExcelImportOfFile,
  InputCaptcha,
  ReferenceSelect,
  CommonApiSelect,
  MultipleDateChoice,
  ApiSelector,
}

export type ComponentType = keyof typeof componentTypeMap |

  /** 页面传入的自定义组件 */
  'Custom'


export type ComponentProp<T extends ComponentType> = {
  'Input': InputProps,
  'InputPassword': InputProps,
  'InputSearch': InputProps,
  'InputNumber': InputNumberProps,
  'DatePicker': DatePickerProps,
  'MonthPicker': MonthPickerProps,
  'WeekPicker': WeekPickerProps,
  'DateRangePicker': RangePickerProps,
  'TimePicker': TimePickerProps,
  'Switch': SwitchProps,
  'Radio': RadioProps,
  'Select': SelectProps,
  'Cascader': CascaderProps,
  'Checkbox': CheckboxProps,

  // 自定义通用组件
  'CustomApiSelect': CustomApiSelectProps,
  'InputMobile': InputMobileProps,
  'InputMoney': InputMoneyProps,
  'RangeMoneyInput': RangeMoneyInputProps,
  'RegionSelect': RegionSelectProps,
  'InputTextArea': InputTextAreaProps,
  'PureDisplay': PureDisplayProps,


  // 业务组件，调用特定业务接口
  'IdCardInput': IdCardInputProps,
  'Upload': UploadProps,
  'InputCaptcha': InputCaptchaProps,
  'ExcelImport': ExcelImportProps,
  'ExcelImportOfFile': ExcelImportOfFileProps,
  'ReferenceSelect': ReferenceSelectProps,
  'CommonApiSelect': CommonApiSelectProps,
  'MultipleDateChoice': MultipleDateChoiceProps,
  'ApiSelector': ApiSelectorProps,
  [K: string]: any,
}[T]
