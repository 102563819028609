<style lang="less" scoped>
@nav-size-height: 60px;
@layout-max-width: 1100px;

.layout {
  height: 100vh;
  width: 100%;
}

.layout-navbar {
  padding: 0;
  // background-color: #e94d07;
  background-color: #FFFFFF;
}

.layout-sider {
  position: "fixed";
  top: 0;
  bottom: 0;
  left: 0;
  overflow: "auto";
  height: "100vh";

  .logo {
    margin: 10px 28px;
    height: 32px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    background-image: url("@images/logo-text-white.png");

    &.collapsed {
      background-image: url("@images/logo.png");
      margin: 12px;
    }
  }
}
</style>

<template>
  <!-- style="height: calc(100vh - 4px)" 解决未知高度问题 -->
  <a-layout
    class="layout"
    style="height: calc(100vh - 4px);"
    theme="light"
  >
    <a-layout-sider
      v-model:collapsed="collapsed"
      class="layout-sider"
      collapsible
      :collapsed-width="48"
      theme="dark"
    >
      <div :class="{ logo: true, collapsed: collapsed }" />
      <Menu />
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="shadow layout-navbar">
        <nav-bar />
      </a-layout-header>
      <a-layout-content class="overflow-auto">
        <Content />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script lang="ts" setup>
import NavBar from './components/navbar/index.vue'
import Menu from './components/menu.vue'
import Content from './components/content.vue'

const collapsed = ref(false)
</script>
